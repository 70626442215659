@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;500;600&display=swap");
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "Poppins", sans-serif;
  outline: none;
  -webkit-appearance: none;
}

body {
  background: #fbf8f8;
}

select {
  background-color: #fff;
  cursor: pointer;
}

input {
  cursor: pointer;
}

button {
  cursor: pointer;
}
